import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  InputSignal,
  OutputEmitterRef,
  Signal,
  TemplateRef,
  ViewChild,
  computed,
  input,
  output,
} from '@angular/core';
import { MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';

import { TemplateContext, TypedTemplateDirective } from '@core/shared/util';

import { OptionComponent, SelectOption } from '../../option';

export interface AutocompleteOptionTemplateContext<OptionValueType>
  extends TemplateContext<SelectOption<OptionValueType>> {
  isActive: boolean;
}

export type AutocompleteOptionTemplateRef<OptionValueType> = TemplateRef<
  AutocompleteOptionTemplateContext<OptionValueType>
>;

@Component({
  selector: 'mp-autocomplete-panel',
  standalone: true,
  templateUrl: './autocomplete-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgTemplateOutlet,

    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    CdkVirtualScrollViewport,
    MatAutocompleteModule,

    OptionComponent,
    TypedTemplateDirective,
  ],
})
export class AutocompletePanelComponent<T> {
  @HostBinding() readonly class = 'mp-autocomplete-panel';

  @ViewChild('autocompletePanel', { read: MatAutocomplete, static: true })
  matAutocomplete!: MatAutocomplete;

  readonly options: InputSignal<SelectOption<T>[]> = input.required<SelectOption<T>[]>();

  readonly optionTemplate: InputSignal<AutocompleteOptionTemplateRef<T> | undefined> =
    input<AutocompleteOptionTemplateRef<T>>();
  readonly emptyOptionsListTemplate: InputSignal<TemplateRef<unknown> | undefined> = input<TemplateRef<unknown>>();
  readonly hideEmptyOptionsList: InputSignal<boolean> = input<boolean>(false);

  readonly autoActiveFirstOption: InputSignal<boolean> = input<boolean>(false);

  readonly panelClass: InputSignal<string> = input<string>('');

  readonly useVirtualScroll: InputSignal<boolean> = input<boolean>(false);
  readonly virtualScrollItemSize: InputSignal<number> = input<number>(80);

  readonly valueSelected: OutputEmitterRef<T> = output<T>();

  protected readonly isVirtualScrollEnabled: Signal<boolean> = computed(
    // Enable virtual scroll only if there are many options. This is needed because
    // virtual scroll requires a fixed height for the container, and it can only be set as a fixed
    // value only if there is enough options to fill the container. Otherwise, we would
    // see a lot of empty space in the container when there are few or no options.
    () => this.useVirtualScroll() && this.options().length > this.virtualScrollItemsThreshold,
  );

  readonly virtualScrollItemsThreshold = 10;

  readonly optionTemplateContextType!: AutocompleteOptionTemplateContext<T>;

  protected readonly optionWrapperTemplateContextType!: TemplateContext<SelectOption<T>>;

  protected displayFn: (optionValue: T | null) => string = (optionValue: T | null) =>
    this.getSelectedOption(optionValue)?.label ?? '';

  private getSelectedOption(optionValue: T | null): SelectOption<T> | undefined {
    return this.options().find(({ value, isResetOption }) => !isResetOption && optionValue === value);
  }

  emitSelectedValue(selectedValue: T): void {
    this.valueSelected.emit(selectedValue);
  }

  trackByFn(index: number): number {
    return index;
  }
}
